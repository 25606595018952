<template>
  <div class="add-member">
    <el-dialog 
      :visible="dialogVisibleAdd"
      :close-on-click-modal="false"
      custom-class="add-member-dialog"
      @close="handleClose"
    >
      <div slot="title">
        {{ $t(`accountManagement.member.add`) }}
        <el-divider />
      </div>
      <el-form
        ref="editMemberForm"
        :model="editMemberForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item :label="$t(`accountManagement.role`)" prop="role">
          <el-select 
            v-model="editMemberForm.role_id"
            filterable
            @change="roleSelected"
          >
            <el-option 
              v-for="item in roleOptionListforAdd"
              :key="item.id"
              :label="item.display"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <template>
          <el-form-item v-if="addAdminTeam" :label="$t(`accountManagement.organization`)">
            <el-input :placeholder="$t(`accountManagement.Authorities`)" disabled />
          </el-form-item>
          <el-form-item v-else :label="$t(`accountManagement.organization`)" prop="org">
            <el-select v-model="editMemberForm.org" filterable>
              <el-option 
                v-for="item in orgOptionList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </template>
        <template v-if="addSubadmin">
          <el-form-item :label="$t(`accountManagement.viewOrganization`)" prop="org">
            <el-select v-model="editMemberForm.below_organization_ids" multiple clearable filterable>
              <el-option 
                v-for="item in viewOrgOptionList"
                :key="item.id"
                :label="item.label"
                :value="item.id" 
              />
            </el-select>
          </el-form-item>
        </template>
        <div class="login-container">
          <el-form-item :label="$t(`accountManagement.login`)" prop="login">
            <el-input v-model="editMemberForm.login" autocomplete="off" />
          </el-form-item>
          <CheckAccountDuplicateButton 
            :login-value="editMemberForm.login"
            @isCheckDuplicate="handleIsCheckLoginDuplicate"
          />
        </div>
        <el-form-item :label="$t(`accountManagement.name`)" prop="name">
          <el-input v-model="editMemberForm.name" />
        </el-form-item>
        <el-form-item 
          :label="$t(`accountManagement.password`)" 
          prop="password" 
          class="pwd-container"
        >
          <el-input 
            ref="password1" 
            v-model="editMemberForm.password" 
            autocomplete="off"
            :type="passwordType1"
          />
            
          <span
            class="show-pwd"
            @click="showPwd(1)"
          >
            <em :class="passwordType1 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
          <div class="from-left white-space">
            {{ $t('accountManagement.PasswordRule') }}
          </div>
        </el-form-item>
        <el-form-item 
          :label="$t(`accountManagement.confirmNewPassword`)" 
          prop="confirmPassword"
          class="pwd-container"
        >
          <el-input 
            ref="password2" 
            v-model="editMemberForm.confirmPassword" 
            autocomplete="off"
            :type="passwordType2" 
          />
          <span
            class="show-pwd"
            @click="showPwd(2)"
          >
            <em :class="passwordType2 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.email`)" prop="email">
          <el-input v-model="editMemberForm.email" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.phone`)">
          <el-input v-model="editMemberForm.phone" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.department`)">
          <el-input v-model="editMemberForm.department" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.title`)">
          <el-input v-model="editMemberForm.title" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.state`)" :label-width="formLabelWidth" class="radio-style">
          <el-radio-group v-model="editMemberForm.status">
            <el-radio label="false"> {{ $t('accountManagement.active') }}</el-radio>
            <el-radio label="true">{{ $t('accountManagement.inactive') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="handleClose">{{ $t('general.Cancel') }}</el-button>
        <el-button type="success" plain @click="submitForm('editMemberForm')">{{ $t('general.Save') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CheckAccountDuplicateButton from '@/components/Button/CheckAccountDuplicateButton.vue'
import { getOrganization } from '@/api/organization'
import { postMember } from '@/api/member'
import { MemberRole, SuccessMessage } from '@/Mixins'

export default {
  name: 'AddMember',
  components: { CheckAccountDuplicateButton },
  mixins: [MemberRole, SuccessMessage],
  props: {
    dialogVisibleAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length > 0 && value.length < 8) {
        callback(new Error(this.$t('RuleMsg.PasswordLimit')))
      } else {
        callback()
      }
    }
    const checkRepeatPwd = (rule, value, callback) => {
      if (value !== this.editMemberForm.password) {
        callback(new Error(this.$t('RuleMsg.PasswordNotSame')))
      } else {
        callback()
      }
    }
    return {
      editMemberForm: {
        role_id: 1,
        org: null,
        below_organization_ids: [],
        login: '',
        name: '',
        password: '',
        confirmPassword: '',
        email: '',
        phone: '',
        department: '',
        title: '',
        status: 'false'
      },
      rules: {
        login: [
          { 
            required: true,
            message: this.$t('general.PleaseInput') + this.$t('general.Account'),
            trigger: 'blur'
          }
        ],
        org: [
          { 
            required: true,
            message: this.$t('general.PleaseInput') + this.$t('accountManagement.org'),
            trigger: 'blur'
          }
        ],
        name: [
          { 
            required: true,
            message: this.$t('general.PleaseInput') + this.$t('accountManagement.name'),
            trigger: 'blur'
          }
        ],
        password: [
          { 
            required: true,
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[\w!@#$%^&*()+|{}\[\]`~\-'";:/?.\\>,<]{8,20}$/,
            message: this.$t('RuleMsg.Invalid') + this.$t('RuleMsg.Password'),
            trigger: 'blur'
          },
          { validator: validatePassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { 
            required: true,
            message: this.$t('RuleMsg.PleaseInput') + ' ' + this.$t('general.Account'),
            trigger: 'blur'
          },
          { validator: checkRepeatPwd, trigger: 'blur' }
        ],
        email: [
          { 
            required: true,
            message: this.$t('general.PleaseInput') + this.$t('accountManagement.email'),
            trigger: 'blur'
          }
        ],
        role_id: [
          { 
            required: true,
            message: this.$t('general.PleaseInput') + this.$t('accountManagement.role') 
          }
        ]
      },
      formLabelWidth: '120px',
      orgOptionList: [], 
      viewOrgOptionList: [], 
      
      roleOptionforOwnerList: [
        {
          id: 1,
          display: this.$t(`Organization.Member`),
          name: 'Member'
        }
      ],
      roleOptionListforAdd: [
        {
          id: 1,
          display: this.$t(`Organization.Member`),
          name: 'Member'
        },
        {
          id: 4,
          display: this.$t(`Organization.SubAdmin`),
          name: 'Administrator'
        },
        {
          id: 5,
          display: this.$t(`Organization.Administrator`),
          name: 'Administrator'
        }
      ],
      isCheckLoginDuplicate: false,
      memberId: 0,
      passwordType1: 'password',
      passwordType2: 'password',
      addAdmin: false,
      addSubadmin: false,
      addAdminTeam: false
    }
  },
  mounted() {
    this.loadData()
    this.initRole()
  },
  methods: {
    loadData () {
      this.fetchGetOrganizationList()
    },
    initRole() {
      this.role = this.role
    },
    async fetchGetOrganizationList() {
      try {
        const res = await getOrganization()
        const data = res.data.organization_list
        this.orgOptionList = data.map((item) => {
          const newObj = {
            ...item,
            label: item.display,
            value: item.id
          }
          return newObj
        })
        this.viewOrgOptionList = data.map((item) => {
          const newObj = {
            ...item,
            label: item.display,
            value: item.id
          }
          return newObj
        })
        this.viewOrgOptionList.unshift(
          {
            label: '全不選',
            value: 9999,
            id: 9999
          })
      } catch (error) {
        console.error('getOrganization-error: ', error)
      }
    },
    async fetchEditMember() {
      let orgList = []
      if (this.addSubadmin) {
        const noneIndex = this.editMemberForm.below_organization_ids.indexOf('9999')
        const count = this.editMemberForm.below_organization_ids.length
        if (count > 1 && noneIndex !== -1) {
        // 選了組織、誤選"全不選"，清掉"全不選"
          orgList = this.editMemberForm.below_organization_ids.splice(noneIndex, 1)
        } else if (count === 1 && noneIndex !== -1) {
        // 只選了"全不選"，送出 [ ]
          orgList = []
        } else {
          orgList = this.editMemberForm.below_organization_ids
        }
      }
      let newObj = {}
      if (this.editMemberForm.role_id === 4) {
        newObj = {
          role_id: this.editMemberForm.role_id,
          below_organization_ids: orgList,
          login: this.editMemberForm.login,
          name: this.editMemberForm.name,
          organization_id: Number(this.editMemberForm.org),
          password: this.editMemberForm.password,
          email: this.editMemberForm.email,
          phone: this.editMemberForm.phone,
          department: this.editMemberForm.department,
          title: this.editMemberForm.title,
          disabled: this.editMemberForm.status !== 'false' 
        }
      } else {
        newObj = {
          role_id: this.editMemberForm.role_id,
          login: this.editMemberForm.login,
          name: this.editMemberForm.name,
          organization_id: Number(this.editMemberForm.org),
          password: this.editMemberForm.password,
          email: this.editMemberForm.email,
          phone: this.editMemberForm.phone,
          department: this.editMemberForm.department,
          title: this.editMemberForm.title,
          disabled: this.editMemberForm.status !== 'false' 
        }
      }
      try {
        await postMember(newObj)
        this.showSuccessMessage(this.$t('Notify.Added'))
        this.handleClose()
      } catch (error) {
        console.error('postMember-error: ', error)
      }
    },
    showPwd(value) {
      if (value === 1) {
        if (this.passwordType1 === 'password') {
          this.passwordType1 = ''
        } else {
          this.passwordType1 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password1.focus()
        })
      } else {
        if (this.passwordType2 === 'password') {
          this.passwordType2 = ''
        } else {
          this.passwordType2 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password2.focus()
        })
      }
    },
    roleSelected() {
      const role = this.editMemberForm.role_id
      if (role === 5 || role === 4) {
        this.addAdminTeam = true
        this.editMemberForm.org = -1
      } else {
        this.addAdminTeam = false
      }
      switch (role) {
        case 5:
          this.addAdminTeam = true
          this.editMemberForm.org = -1
          this.addSubadmin = false
          break
        case 4:
          this.addAdminTeam = true
          this.editMemberForm.org = -1
          this.addSubadmin = true
          break
        case 1:
          this.addSubadmin = false
          this.editMemberForm.org = null
          break
      }
    },
    handleIsCheckLoginDuplicate(isCheck) {
      this.isCheckLoginDuplicate = isCheck
    },
    submitForm(editMemberForm) {
      this.$refs[editMemberForm].validate((valid) => {
        if (valid) {
          if (this.isCheckLoginDuplicate) {
            this.fetchEditMember()
          } else {
            this.$message({
              title: this.$t('general.Error'),
              message: this.$t('Notify.CheckLoginDuplicate'),
              type: 'error'
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
::v-deep.el-form--label-top .el-form-item__label{
  padding-bottom: 0 !important;
}
::v-deep .add-member-dialog .el-dialog__body{
  padding-top: 0!important;
}
::v-deep.el-divider--horizontal {
  margin-bottom: 0;
}
.add-member{

  ::v-deep .add-member-dialog{
    @media (max-width: 576px) {
      width: 90% ;
      min-width: 280px;
    }
    .el-divider{
      margin-bottom: 0;
    }
    
    .from-left{
      text-align: left;
    }
    .pwd-container{
      position: relative;
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
    }
    .login-container{
      position: relative;
    }
  }  
  
  .el-select{
    width: -webkit-fill-available;
    }
    .radio-style{
      text-align: left;
    }
}
</style>
