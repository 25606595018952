<template>
  <div class="accmenag-container">
    <div class="table-container">
      <el-row class="title-container">
        <el-col :span="8">
          <div class="title-text"> {{ $t('accountManagement.sidebarAccount') }}</div>
        </el-col>
        <el-col :span="16" class="title-right">
          <el-form
            :inline="true"  
            :model="filterOptions"
            label-position="right"
            label-width="60px"
            class="demo-form-inline"
          >
            <el-form-item
              prop="keyword"
            >
              <el-input
                v-model="filterOptions.keyword"
                :placeholder="$t('Form.placeholderKeyword')"
                suffix-icon="el-icon-search"
                clearable
                @change="onChangeFilter"
              />
            </el-form-item>
          </el-form>
          <el-button
            v-if="isAdmin" 
            type="success"
            @click="handleAddMember"
          >
            + {{ $t('accountManagement.member.add') }}
          </el-button>
        </el-col>
      </el-row>
      <el-table
        class="desk-pad-view"
        v-loading="loading"
        :data="tableData"
        stripe
      >
        <el-table-column
          v-for="(item, index) in tableFields"
          :key="index"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :sortable="item.sortable"
          :label="item.label"
        />
        <el-table-column
          v-if="isAdmin"
          :label="$t(`accountManagement.edit`)"
          width="100px"
        >
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              plain
              size="mini"
              @click="handleEditIcon(scope.row)"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty :description="$t('general.NoData')" />
        </template>
      </el-table>
      <el-table
        class="phone-view"
        v-loading="loading"
        :data="tableData"
        stripe
      >
        <el-table-column
          prop="name"
          :label="$t(`accountManagement.name`)"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span><br>
            <span class="role-phone-style">{{ scope.row.role }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isAdmin"
          :label="$t(`accountManagement.edit`)"
          width="60px"
        >
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              plain
              size="mini"
              @click="handleEditIcon(scope.row)"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty :description="$t('general.NoData')" />
        </template>
      </el-table>
      <!-- pagination -->
      <div class="pagination-container">
        <el-pagination
          :background="true"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageLimit"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <AddMember
      v-if="dialogVisibleAdd" 
      :dialog-visible-add="dialogVisibleAdd"
      @closeDialog="emitCloseDialog"
    />
    <EditMember
      v-if="dialogVisible" 
      :dialog-visible="dialogVisible"
      :member-info="memberInfo"
      @closeDialog="emitCloseDialog"
    />
  </div>
</template>

<script>
import EditMember from './EditMember.vue'
import AddMember from './AddMember.vue'
import { MemberRole } from '@/Mixins'

import { getMemberList } from '@/api/member'
const params = () => ({
  page: 1,
  page_limit: 20
})

export default {
  name: 'AccountManagement',
  components: { EditMember, AddMember },
  mixins: [MemberRole],
  data () {
    return {
      tableData: [],
      tableFields: [
        {
          label: this.$t('accountManagement.name'),
          prop: 'name'
        },
        {
          label: this.$t('accountManagement.organization'),
          prop: 'organization'
        },
        {
          label: this.$t('accountManagement.role'),
          prop: 'role',
          width: '200px'
        }
      ],
      filterOptions: {
        keyword: ''
      },
      dialogVisible: false,
      dialogVisibleAdd: false,
      hasMemberId: false,
      memberInfo: {},
      loading: true,
      params: params(),
      currentPage: 1,
      pageLimit: 20,
      totalPage: 0
    }
  },
  computed: {
    searchWidth() {
      return this.isOrgTeam ? 8 : 4
    },
    addMemberWidth() {
      return this.isOrgTeam ? 0 : 4
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.fetchData()
    },
    async fetchData() {
      try {
        this.loading = true
        const res = await getMemberList(this.params)
        const data = res.data.member_list
        this.totalPage = res.data.page.total
        this.tableData = data.map((item) => {
          const newObj = {
            ...item,
            role: this.$t(`Organization.${item.default_role.name}`),
            organization: item.belong_organization.name
          }
          return newObj
        })
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async onChangeFilter() {
      this.params = params()
      this.params.search = this.filterOptions.keyword
      this.fetchData()
    },
    handleEditIcon(row, id) {
      this.dialogVisible = true
      this.hasMemberId = true
      this.memberInfo = row
    },
    handleAddMember() {
      this.dialogVisibleAdd = true
    },
    emitCloseDialog() {
      this.dialogVisibleAdd = false
      this.dialogVisible = false
      this.hasMemberId = false
      this.memberInfo = {}
      this.loadData()
    },
    // for pagination
    handleSizeChange(val) {
      this.params.page_limit = val
      this.loadData()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loadData()
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.accmenag-container{
  width: 100%;
  .demo-form-inline{
    display: contents;
  }
  .el-divider{
    margin: 24px 0 0;
  }
  @media (min-width: 577px) { 
    .desk-pad-view{
      display: block;
    }
    .phone-view{
      display: none;
    } 
  }
  @media (max-width: 576px) { 
    .desk-pad-view{
      display: none;
    }
    .phone-view{
      display: block;
      .role-phone-style{
        font-weight: bold;
        color: $link;
      }
    }
  }
  
  .table-container{
    padding: 0 20px;
    
    .title-container{
      margin: 10px 0;
      .title-text{
        font-size: 20px;
        line-height: 2;
        text-align: left;
      }
      .title-right{
        text-align: end;
        .el-button {
          margin-bottom: 5px;
        }
      }
    }
  }
}
/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
