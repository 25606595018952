<template>
  <div class="edit-member">
    <el-dialog 
      :visible="dialogVisible"
      custom-class="edit-member-dialog"
      @close="handleClose"
    >
      <div slot="title">
        {{ $t(`accountManagement.member.edit`) }}
        <el-divider />
      </div>
      <el-form
        ref="editMemberForm"
        :model="editMemberForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item :label="$t(`accountManagement.role`)" prop="role_id">
          <el-select v-model="editMemberForm.role_id" filterable disabled>
            <el-option 
              v-for="item in roleOptionList"
              :key="item.id"
              :label="item.display"
              :value="item.id"
            />
          </el-select>
        </el-form-item>     
        <!-- view only -->
        <el-form-item :label="$t(`accountManagement.organization`)">
          <el-input v-model="belong_organization_name" disabled />
        </el-form-item>  
        <!-- 給 subadmin 用的 多選 可編輯-->
        <template v-if="editOrglist">
          <el-form-item 
            :label="$t(`accountManagement.viewOrganization`)" 
          >
            <el-select 
              v-model="editMemberForm.below_organization_ids"
              filterable
              multiple
              @change="$forceUpdate()"
            >
              <el-option 
                v-for="item in OrgOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </template>
        <el-form-item :label="$t(`accountManagement.login`)" prop="login">
          <el-input v-model="editMemberForm.login" autocomplete="off" disabled />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.name`)" prop="name">
          <el-input v-model="editMemberForm.name" autocomplete="off" />
        </el-form-item>
        <template>
          <el-form-item :label="$t(`accountManagement.resetPassword`)" prop="password" class="pwd-container">
            <el-input 
              ref="password1"
              v-model="editMemberForm.password"
              autocomplete="off"
              :type="passwordType1"
            />
            <span 
              class="show-pwd"
              @click="showPwd(1)"
            >
              <em :class="passwordType1 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
            </span>
            <div class="from-left white-space">
              {{ $t('accountManagement.PasswordRule') }}
            </div>
          </el-form-item>
        </template>
        
        <el-form-item 
          :label="$t(`accountManagement.confirmNewPassword`)" 
          prop="confirmPassword"
          class="pwd-container"
        >
          <el-input 
            ref="password2" 
            v-model="editMemberForm.confirmPassword" 
            autocomplete="off"
            :type="passwordType2" 
          />
          <span
            class="show-pwd"
            @click="showPwd(2)"
          >
            <em :class="passwordType2 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.email`)" prop="email">
          <el-input v-model="editMemberForm.email" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.phone`)">
          <el-input v-model="editMemberForm.phone" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.department`)">
          <el-input v-model="editMemberForm.department" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.title`)">
          <el-input v-model="editMemberForm.title" />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.state`)" :label-width="formLabelWidth" class="radio-style">
          <el-radio-group v-model="editMemberForm.disabled">
            <el-radio :label="false"> {{ $t('accountManagement.active') }}</el-radio>
            <el-radio :label="true">{{ $t('accountManagement.inactive') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="delete-btn" type="danger" plain @click="handleDelete">{{ $t('general.Delete') }}</el-button>
        <el-button type="info" plain @click="handleClose">{{ $t('general.Cancel') }}</el-button>
        <el-button type="success" plain @click="submitForm('editMemberForm')">{{ $t('general.Save') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('general.Warning')"
      :visible.sync="deletedialogVisible"
      width="40%"
    >
      <div>{{ $t('accountManagement.isDeleteMember') }}</div>
      <div>{{ deletedLogin }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="fetchDelete">{{ $t('general.Delete') }}</el-button>
        <el-button type="info" @click="deletedialogVisible = false">{{ $t('general.Cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('general.Warning')"
      :visible.sync="stopdialogVisible"
      width="40%"
    >
      <div>{{ $t('accountManagement.accIsOwnerDESC') }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="stopdialogVisible = false">{{ $t('general.Cancel') }}</el-button>
        <el-button type="success" plain>
          <router-link to="/Account/OrgManagement">
            {{ $t('accountManagement.toOrg') }}
          </router-link>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getInfoData } from '@/api/data'
import { updateUser, deleteUser } from '@/api/member'
import { MemberRole, SuccessMessage, OrganizationData } from '@/Mixins'

export default {
  name: 'EditMember',
  mixins: [MemberRole, SuccessMessage, OrganizationData],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    memberInfo: {
      type: Object,
      default: () => []
    }
  },
  data () {
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback()
      } else if (value.length > 0 && value.length < 8) {
        callback(new Error(this.$t('RuleMsg.PasswordLimit')))
      } else {
        callback()
      }
    }
    const checkRepeatPwd = (rule, value, callback) => {
      if (value !== this.editMemberForm.password) {
        callback(new Error(this.$t('RuleMsg.PasswordNotSame')))
      } else {
        callback()
      }
    }
    return {
      editMemberForm: {
        role_id: 1,
        org: null,
        below_organization_ids: [],
        login: '',
        name: '',
        password: '',
        confirmPassword: '',
        email: '',
        phone: '',
        department: ' ',
        title: ' ',
        disabled: false
      },
      rules: {
        password: [
          { 
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[\w!@#$%^&*()+|{}\[\]`~\-'";:/?.\\>,<]{8,20}$/,
            message: this.$t('RuleMsg.Invalid') + this.$t('RuleMsg.Password'),
            trigger: 'blur'
          },
          { validator: validatePassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { message: this.$t('RuleMsg.InputRepeatPwd'), trigger: 'blur' },
          { validator: checkRepeatPwd, trigger: 'blur' }
        ]
      },
      formLabelWidth: '120px',
      roleOptionforOwnerList: [
        {
          id: 1,
          display: '成員',
          name: 'Member'
        }
      ],
      roleOptionList: [],

      // delete
      memberId: 0,
      deletedialogVisible: false,
      deletedLogin: '',
      stopdialogVisible: false,

      passwordType1: 'password',
      passwordType2: 'password',
      belong_organization_name: '',
      editOrglist: false
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData () {
      await this.fetchGetOrganizationList()
      await this.fetchInfo()
    },
    async fetchGetOrganizationList() {
      this.setEditFormData()
    },
    async fetchInfo() {
      const res = await getInfoData(this.paraStr)
      const data = res.data.role
      this.roleOptionList = data.map((item) => {
        const newObj = {
          ...item,
          display: this.$t(`Organization.${item.name}`)
        }
        return newObj
      })
    },
    async fetchEditMember() {
      let newObj = {}
      if (!this.editMemberForm.password && !this.editMemberForm.confirmPassword) {
        newObj = {
          name: this.editMemberForm.name || '',
          email: this.editMemberForm.email,
          phone: this.editMemberForm.phone || '',
          below_organization_ids: this.editMemberForm.below_organization_ids,
          department: this.editMemberForm.department || '',
          title: this.editMemberForm.title || '',
          disabled: this.editMemberForm.disabled 
        }
      } else {
        newObj = {
          name: this.editMemberForm.name || '',
          email: this.editMemberForm.email,
          phone: this.editMemberForm.phone || '',
          below_organization_ids: this.editMemberForm.below_organization_ids,
          department: this.editMemberForm.department || '',
          disabled: this.editMemberForm.disabled,
          password: this.editMemberForm.password,
          title: this.editMemberForm.title || ''
        }
      }
      try {
        await updateUser(this.memberId, newObj)
        this.showSuccessMessage(this.$t('Notify.Updated'))
        
        this.handleClose()
      } catch (error) {
        console.error(error)
      }
    },
    async fetchDelete() {
      if (this.memberId) {
        try {
          await deleteUser(this.memberId)
          this.showSuccessMessage(this.$t('Notify.Deleted'))
        } catch (error) {
          console.error(error)
        }
      }
      this.memberId = ''
      this.deletedLogin = ''
      this.deletedialogVisible = false
      this.handleClose()
    },
    setEditFormData() {
      this.memberId = this.memberInfo.id
      this.editMemberForm = this.memberInfo
      
      this.editMemberForm.role_id = this.memberInfo.default_role.id
      this.editMemberForm.department = this.memberInfo.department || ''
      this.editMemberForm.title = this.memberInfo.title || ''
      this.editMemberForm.phone = this.memberInfo.phone || ''
      this.belong_organization_name = this.memberInfo.belong_organization.name // view only
      // edit subadmin only
      if (this.memberInfo.default_role.id === 4) {
        this.editOrglist = true

        const below_orgs = this.memberInfo.below_organizations
        const idsArray = []
        below_orgs.forEach((item) => {
          idsArray.push(item.id)
        })
        this.editMemberForm.below_organization_ids = idsArray
      }
    },
    showPwd(value) {
      if (value === 1) {
        if (this.passwordType1 === 'password') {
          this.passwordType1 = ''
        } else {
          this.passwordType1 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password1.focus()
        })
      } else {
        if (this.passwordType2 === 'password') {
          this.passwordType2 = ''
        } else {
          this.passwordType2 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password2.focus()
        })
      }
    },
    submitForm(editMemberForm) {
      this.$refs[editMemberForm].validate((valid) => {
        if (valid) {
          this.fetchEditMember()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleDelete() {
      if (this.editMemberForm.role_id === 3) {
        this.stopdialogVisible = true
      } else {
        this.deletedialogVisible = true
        this.deletedId = this.editMemberForm.id
        this.deletedLogin = this.editMemberForm.login
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
::v-deep.el-form--label-top .el-form-item__label{
  padding-bottom: 0 !important;
}
::v-deep .edit-member-dialog .el-dialog__body{
  padding-top: 0!important;
}
::v-deep.el-divider--horizontal {
  margin-bottom: 0;
}
.edit-member{
  ::v-deep .edit-member-dialog{
    @media (max-width: 576px) {
      width: 90% ;
      min-width: 280px;
    }
    .el-divider{
      margin-bottom: 0;
    }
    
    .from-left{
      text-align: left;
    }
    .pwd-container{
      position: relative;
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
    }

    .dialog-footer{
      position: relative;
      .delete-btn{
        position: absolute;
        left: 0;
      }
    }
  }  
  
  .el-select{
    width: -webkit-fill-available;
    }
    .radio-style{
      text-align: left;
    }
}
</style>
